/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */
@font-face {
    font-family: 'amavickfont';
    src: url('../fonts/amavickfont.ttf');
}

body{
    margin: 0px !important;
}

#font-loader {
    font-family: 'amavickfont';
    position: absolute;
    visibility: hidden;
    bottom: -500%;
}

:root {
    --women_color: #fe76fc;
    --men_color: #877eff;
    --like_color: #FF4FFF;
    --height_tab: 0px;
    --vk_header: 88px;
    --total_text_cards: 0px;
    --swipe_icon_scale: 0;
    --swipe_icon_right: 'auto';
    --swipe_icon_left: 'auto';
}
.welcome {
    height: 100vh;
}
.btn-fixed {
    position: fixed;
    top: 50%;
}
.left-btn {
    left: 0;
}
.right-btn {
    right: 0;
}
.leaflet-container {
    height: 100%;
    width: 100%;
}
.online {
    background: var(--dynamic_green);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
}
.flexInlineApp {
    display: flex;
    align-items: center;
}

/*
.tab_card .TabsItem, .tab_card .TabsItem__in{
    overflow: hidden;
    text-overflow: ellipsis;
}*/

.card-empty {
    margin: 50% 2em;
    padding: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: var(--dynamic_blue);
    border-radius: 10px;
    color: white;
    text-shadow: 1px 1px #000;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.53);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.53);
}
.assets-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: var(--background_page);
    margin: 1rem;
    padding: 1rem;
    border-radius: .5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.assets-item {
    margin: .5rem;
    background: var(--button_primary_background);
    border-radius: .5rem;
    color: white;
    font-size: .8rem;
    padding: .5rem;
    display: inline-block;
    position: relative;
}
.row-images{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: .25em;
    -moz-column-gap: .25em;
    column-gap: .25em;
}
.col-image{
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    padding: .125em;
    border: 2px solid var(--background_page);
    margin-bottom: .25em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.col-image img {
    max-width: 100%;
}
.col-image > div{
    z-index: 1;
}
.image-full, .bg-main-image {
    position: relative;
}
.col-image-2 .delete-img, .col-image-3 .delete-img {
    position: absolute;
    right: -10px;
    top: -10px;
}
.col-image-2 .edit-img, .col-image-3 .edit-img {
    position: absolute;
    right: -10px;
    bottom: -10px;
}
.col-image-0 .delete-img, .col-image-1 .delete-img {
    position: absolute;
    left: -10px;
    top: -10px;
}
.col-image-0 .edit-img, .col-image-1 .edit-img{
    position: absolute;
    left: -10px;
    bottom: -10px;
}
.cell-img .Cell__children {
    overflow: unset;
}
.bg-main-image .edit-img {
    position: absolute;
    right: -15px;
    bottom: -15px;
}
.isUser::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: rgba(69, 103, 143, 0.75);
    z-index: 0;
}

.grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 25% auto 25%;
    grid-template-columns: 25% auto 25%;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "left-1 center right-1" "left-2 center right-2";
}
.center { -ms-grid-row: 1; -ms-grid-row-span: 2; -ms-grid-column: 2; grid-area: center; }

.col-image-0 { -ms-grid-row: 1; -ms-grid-column: 1; grid-area: left-1; }

.col-image-1 { -ms-grid-row: 2; -ms-grid-column: 1; grid-area: left-2; -ms-grid-row-align: end; align-self: end;}

.col-image-2 { -ms-grid-row: 1; -ms-grid-column: 3; grid-area: right-1; }

.col-image-3 { -ms-grid-row: 2; -ms-grid-column: 3; grid-area: right-2; -ms-grid-row-align: end; align-self: end; }

.bg-mini-image {
    background: var(--placeholder_icon_background);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 56px;
    height: 56px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: auto;
    border-radius: .5em;
    background: var(--dynamic_blue);
}

.counterMessage {
    color: var(--counter_primary_text);
    border-radius: 50%;
    display: inline-block;
    min-width: 20px;
    height: 20px;
    padding: .25em;
    background: var(--dynamic_blue);
}

.bg-main-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    width: 125px;
    height: 125px;
    border-radius: .5em;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.bg-empty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    width: 125px;
    height: 125px;
    border-radius: .5em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.text-gray {
    font-size: 14px;
    line-height: 18px;
    color: var(--text_secondary);
    margin: 1em 0;
    display: block;
}

.white-slider::before {
    background-color: white;
}

.white-slider .Slider__dragger::before {
    background: var(--poll_option_background);
}

/*
body[scheme="client_dark"] .white-slider .Slider__dragger::before {
    background: var(--dynamic_blue);
}*/

.blue-text {
    color: var(--link_alternate);
}

.slide-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 1em 0;
}

.flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: .25em 0;
    width: 100%;
}

.pr-12 {
    padding-right: 12px;
}

.image-full{
    margin: auto;
    border-radius: .5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: end;
    align-content: flex-end;
}
.message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: .85em;
    position: relative;
}
.message span {
    color: var(--tabbar_inactive_icon);
    padding: 0 .5em;
}
.fromFriend, .fromMe {
    max-width: 70%;
    word-wrap: break-word;
    margin: 5px 15px;
    line-height: 24px;
    position: relative;
    padding: 5px 15px;
}
.fromFriend{
    color:white;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.28);
    border-radius: 10px 10px 10px 0px;
}
.men {
    background: var(--dynamic_blue);
}
.women {
    background: #daafdf;
}
.fromMe {
    background:#FFF;
    color:black;
    -webkit-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
    border-radius: 10px 10px 0px 10px;
}

.fromMe::before {
    width: 0;
    height: 0;
    border-top: 15px solid #fff;
    border-left: 15px solid transparent;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0px, 5px);
}

.isRead::before {
    background:  var(--background_highlighted);
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
}

.d-none{
    display: none !important;
}

.fromFriend::before{
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0px, 5px);
}

.fromFriend.men::before {
    border-top: 15px solid var(--dynamic_blue);
}
.fromFriend.women::before {
    border-top: 15px solid #daafdf;
}

.message-input:focus * {
    outline: 0;
}

.ulList {
    list-style: none;
    padding-left: 0;
    font-size: 12px;
}
.ulList li::before {
    content: "\2022";
    margin-right: 5px;
}

/*map*/
.vk-marker {
    background: transparent;
}
.marker-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid;
}

.men .online-marker {
    -webkit-box-shadow: 0 0 0 var(--dynamic_blue);
    box-shadow: 0 0 0 var(--dynamic_blue);
    -webkit-animation: men-pulse 3s linear infinite;
    animation: men-pulse 3s linear infinite;
}

.women .online-marker {
    -webkit-box-shadow: 0 0 0 var(--dynamic_violet);
    box-shadow: 0 0 0 var(--dynamic_violet);
    -webkit-animation: women-pulse 3s linear infinite;
    animation: women-pulse 3s linear infinite;
}

.vk-popup  {
    color: white;
}

.vk-popup h4 {
    margin-bottom: 0;
}

.vk-popup a.leaflet-popup-close-button {
    color: white;
}

.vk-popup .leaflet-popup-content-wrapper  {
    border-radius: 10px;
    color: white;
}

.popup-men .leaflet-popup-content-wrapper {
    background: var(--men_color);
}

.popup-men .leaflet-popup-tip {
    background: var(--men_color);
}

.popup-women .leaflet-popup-tip {
    background: var(--women_color);
}

.popup-women .leaflet-popup-content-wrapper {
    background: var(--women_color);
}

#messagesContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: calc(20px + var(--safe-area-inset-bottom));
}

/*settings popup*/
.settingsBox .label-s {
    max-width: 115px;
}
.settingsBox label {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}
.settingsBox input + div {
    border-radius: 5px;
    padding: .5em;
}
.settingsBox input:checked + div.womenIcon {
    -webkit-box-shadow: 0px 0px 10px 5px var(--dynamic_red);
    box-shadow: 0px 0px 10px 5px var(--dynamic_red);
}
.settingsBox input:checked + div.menIcon {
    -webkit-box-shadow: 0px 0px 10px 5px var(--dynamic_blue);
    box-shadow: 0px 0px 10px 5px var(--dynamic_blue);
}
/**/

.slick-slide div:focus, button:focus {
    outline: none !important;
}

@-webkit-keyframes women-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 118, 252,.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(254, 118, 252, .25);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 118, 252, .125);
    }
}
@keyframes women-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 118, 252,.5);
        box-shadow: 0 0 0 0 rgba(254, 118, 252,.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(254, 118, 252, .25);
        box-shadow: 0 0 0 15px rgba(254, 118, 252, .25);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 118, 252, .125);
        box-shadow: 0 0 0 0 rgba(254, 118, 252, .125);
    }
}

@-webkit-keyframes men-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(135,126,255, .5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(135,126,255, .25);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(135,126,255, .125);
    }
}
@keyframes men-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(135,126,255, .5);
        box-shadow: 0 0 0 0 rgba(135,126,255, .5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(135,126,255, .25);
        box-shadow: 0 0 0 15px rgba(135,126,255, .25);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(135,126,255, .125);
        box-shadow: 0 0 0 0 rgba(135,126,255, .125);
    }
}

.top-animation{
    animation: topAnimate ease-in-out 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: topAnimate ease-in-out 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: topAnimate ease-in-out 1s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: topAnimate ease-in-out 1s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: topAnimate ease-in-out 1s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
}

@keyframes topAnimate{
    0% {
        transform:  translate(0px,0px)  ;
    }
    50% {
        transform:  translate(0px,10px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

@-moz-keyframes topAnimate{
    0% {
        transform:  translate(0px,0px)  ;
    }
    50% {
        transform:  translate(0px,10px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

@-webkit-keyframes topAnimate {
    0% {
        transform:  translate(0px,0px)  ;
    }
    50% {
        transform:  translate(0px,10px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

@-o-keyframes topAnimate {
    0% {
        transform:  translate(0px,0px)  ;
    }
    50% {
        transform:  translate(0px,10px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

@-ms-keyframes topAnimate {
    0% {
        transform:  translate(0px,0px)  ;
    }
    50% {
        transform:  translate(0px,10px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

/*Slick slider*/
.slick-slider {
    height: 100%;
}
.slick-list, .slick-track, .slick-slide, .slick-slide div {
    height: 100% !important;
}
.vk-dots {
    position: absolute;
    top: 0;
    right: 0;
    list-style: none;
    padding: 0;
}
.vk-dots li button::before {
    content: '';
    width: 10px;
    height: 10px;
    margin: 0 .5em;
    border: 2px solid #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.vk-dots li button {
    color: transparent;
    background: transparent;
    border: none;
    height: 15px;
}
.vk-dots li.slick-active button::before{
    background: #fff;
}

.errorText {
    color: var(--field_error_border);
    padding: 0 16px;
    font-size: 13px;
    margin: 0;
}

.error-input {
    border-color: var(--field_error_border);
}

/*End*/

.slide-row > div {
    flex: 1 1 auto;
}
.slide-row > div:first-child {
    max-width: 2em;
}
.slide-row > .chevron-div {
    text-align: right;
}
.slide-register svg {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.slide-register svg[toggle="EXPANDED"], .slide-register svg[toggle="EXPANDING"]{
    transform: rotate(-90deg);
}

.center-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
}

/**/

/*Dark Theme*/
/*body[scheme="client_dark"] {
    --background_page: #aaaeb3;
    --accent: #b8cde4;
}*/
/*End*/
/*welcome*/
.welcome h4 {
    font-size: 14px;
}
.welcome p {
    font-size: 12px;
}
/*end*/
.fslightbox-container {
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgb(0, 0, 0),#000 1810%) !important;
}
.w-100 {
    width: 100%;
}
.no-give {
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.icon-div {
    width: 2em;
}
.fslightbox-toolbar-button {
    display: none !important;
}

.leaflet-control-attribution {
    padding: .75em;
}

.map-container {
    position: relative;
    height: calc(100vh - var(--panelheader_height_android) - var(--safe-area-inset-top) - var(--panelheader_height_android) + var(--safe-area-inset-top));
    display: flex;
    padding: 0 ;
    z-index: 1;
}

.fslightbox-slide-number-container {
    display: none !important;
}

.pay-row {
    font-size: 13px;
    margin-bottom: -15px;
}

.Group>.pay-row:first-child {
    margin-top: 0px;
}

.modal-pay .FormLayout__row-top {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
}

.modal-pay .Header__content {
    margin: auto;
}
.pay--col {
    height: 55px;
    text-align: center;
    font-size: 14px;
    display: flex;
    color: var(--text_primary);
    align-items: center;
    justify-content: center;
}
.pay--label {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -10px;
    text-align: center;
    color: #fff;
    padding: 0 .25em;
    border-radius: 15px;
    font-size: 12px;
    width: 75%;
    margin: auto;
    line-height: 1.5;
}
.success-pay {
    background: var(--dynamic_green);
}
.discount-pay{
     background: var(--dynamic_violet);
 }
/*counter*/
.counterDown {

}
.avatarAround {
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}
.text-center {
    text-align: center;
}
.counterCol{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--dynamic_red);
    color: #fff;
    padding: .5em;
    border-radius: 10px;
    transition: all .5s ease-in-out;
}
.counterCol .delimiter{
    width: 1px;
    height: 30px;
    background-color: #fff;
}
/*end counter*/
.userCard {
    height: calc(100vh - var(--panelheader_height_android) - var(--height_tab, 0) - (var(--tabbar_height) * 1.5) - var(--total_text_cards, 0px) - var(--safe-area-inset-bottom) - var(--safe-area-inset-top) - 24px);
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.userAvatar {
    padding-top: 35vh;
}
.cardUserWrapper {
    flex: 1;
    position: relative;
    border-radius: 1em;
    overflow: hidden;
}
.cardUserBottom {
    color: #fff;
    /*background: rgba(0,0,0,.85);*/
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
}
.cardInnerImage {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--control_background);
}
.cardDescription {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
}
 .slick-vertical .slick-slide {
     border: none;
 }
 .logo_boot {
     max-width: 100%;
     width: 100%;
 }

 .chatRoom {
     padding-bottom: var(--safe-area-inset-bottom, 0px) !important;
 }

 .card_title {

 }
 .pd-0 {
     padding-bottom: 0 !important;
 }
 .top-right {
     position: absolute;
     color: var(--button_primary_background);
     opacity: .25;
     top: 0;
     right: 0;
 }
.active-tab-menu {
    position: absolute;
    bottom: calc(0px + var(--tabbar_height));
    background: var(--background_content);
    z-index: 1;
    padding: 25px;
    color: white;
    width: 100%;
}
.active-tab-menu .tab-menu-item {
    display: flex;
    align-items: center;
    flex-flow: column;
    text-align: center;
    min-height: 115px;
    border-radius: 10px;
}
.full-height {
    height: 100vh;
}

.full-height-adaptive {
    height: calc(100vh - var(--panelheader_height_ios) - var(--tabbar_height) - var(--safe-area-inset-bottom) - 45px);
}

.panel-min-height-100 {
    min-height: 100vh;
}

.flex-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.swipe-wrapper {
    overflow: hidden;
    position: relative;
    height: calc(100vh - var(--panelheader_height_android) - var(--height_tab, 0) - (var(--tabbar_height) * 1.5) - var(--total_text_cards, 0px) - var(--safe-area-inset-bottom) - var(--safe-area-inset-top));
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-flow: wrap;
}

.swipe-wrapper .swipe {
    position: absolute;
    top: 0;
    width: 100%;
}

.leaflet-control-container .leaflet-bottom.leaflet-right a[href*='https://leafletjs.com']{
    display: none !important;
}

@media (min-width: 769px) {
    .tab-menu-row {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 586px) {
    .active-tab-menu {
        padding: 10px;
    }
    .tab-menu-col {
        padding-left: 0;
        padding-right: 0;
    }
    .leaflet-container .leaflet-control-attribution {
        padding-bottom: 25px;
    }
}

#ratio-swipe-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: var(--background_content);
    opacity: .7;
    transform: scale(var(--swipe_icon_scale));
    left: var(--swipe_icon_left);
    right: var(--swipe_icon_right);
}

#ratio-like, #ratio-dislike {
    display: none;
}

.swipe-preloader {
    position: absolute;
    z-index: 9;
}
.swipe-preloader:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--background_page);
}

.link-btn {
    color: var(--button_primary_foreground);
}

